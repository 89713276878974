<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <OrganizationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>
              Referrer tracking plugin for WooCommerce
              <button
                class="btn btn-sm float-right btn-outline-secondary"
                @click="downloadPlugin"
              >
                Download plugin
              </button>
            </h4>
          </div>
          <div class="card-body">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/JSZCxGWbxPw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />

            <a
              href="https://automation.app/uncategorized/automation-app-plugin-for-referrer-tracking/"
              target="_blank"
              >Read full instructions</a
            >
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import Default from "@/layouts/default.vue";

export default {
  components: {
    OrganizationNav,
    Default
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      step_status: {
        key: "download_referer",
        status: true,
      },
    };
  },
  methods: {
    async downloadPlugin() {
      await jsonAPI({
        url: "/download/referrerplugin",
        method: "GET",
        responseType: "blob",
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([resp.data], { type: "application/octet-stream" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "woo-doc-referrer.zip");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
